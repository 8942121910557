
//
// social.scss
//

.social-list-item {
    height: 3rem;
    width: 3rem;
    line-height: calc(3rem - 4px);
    display: block;
    border: 2px solid $gray-500;
    border-radius: 50%;
    background: transparent;
    font-size: 20px;
}
