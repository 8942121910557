//
// widgets.scss
//

// icon bg widgets


@each $color, $value in $theme-colors {
    .bg-icon-#{$color} {
        border: 6px solid rgba($value, 0.3) !important;
        background-color: rgba($value, 0.3) !important;
    }
}


// Simple tile box
.widget-flat {
    position: relative;
    overflow: hidden;

    i.widget-icon {
        font-size: 36px;
    }
}

// Inbox-widget(Used Profile)
.inbox-widget {
    .inbox-item {
        border-bottom: 1px solid rgba($border-color,0.5);
        overflow: hidden;
        padding: 0.625rem 0;
        position: relative;

        &:last-of-type {
            border-bottom: none;
        }

        .inbox-item-img {
            display: block;
            float: left;
            margin-right: 15px;
            width: 40px;

            img {
                width: 40px;
            }
        }

        .inbox-item-author {
            color: $text-color-h;
            display: block;
            margin-bottom: 3px;
            font-weight: $label-font-weight;
        }

        .inbox-item-text {
            color: $text-muted;
            display: block;
            font-size: 0.8125rem;
            margin: 0;
            overflow: hidden;
        }

        .inbox-item-date {
            color: $gray-600;
            font-size: 0.6875rem;
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }
}

/* Chat widget */

.conversation-list {
    list-style: none;
    padding: 0 15px;

    li {
        margin-bottom: 20px;

        .conversation-actions {
            float: right;
            display: none;
        }

        &:hover {
            .conversation-actions {
                display: block;
            }
        }

        .conversation-lists {
            display: inline-block;
            position: relative;
        }
    }

    .chat-avatar {
        float: left;
        text-align: center;
        width: 42px;

        img {
            border-radius: 100%;
            width: 100%;
        }

        i {
            font-size: 12px;
            font-style: normal;
        }
    }

    .ctext-wrap {
        background: $chat-secondary-user-bg;
        border-radius: 3px;
        display: inline-block;
        padding: 12px;
        position: relative;

        i {
            display: block;
            font-size: 12px;
            font-style: normal;
            font-weight: $label-font-weight;
            position: relative;
        }

        p {
            margin: 0;
            padding-top: 3px;
        }

        &:after {
            left: -10px;
            top: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top-color: $chat-secondary-user-bg;
            border-width: 6px;
            margin-right: -1px;
            border-right-color: $chat-secondary-user-bg;
        }
    }

    .conversation-text {
        float: left;
        font-size: 12px;
        margin-left: 12px;
        width: 70%;
    }

    .odd {
        .chat-avatar {
            float: right !important;
        }

        .conversation-text {
            float: right !important;
            margin-right: 12px;
            text-align: right;
            width: 70% !important;
        }

        .ctext-wrap {
            background-color: $chat-primary-user-bg;

            &:after {
                border-color: transparent;
                border-left-color: $chat-primary-user-bg;
                border-top-color: $chat-primary-user-bg;
                right: -10px !important;
                left: auto;
            }
        }

        .conversation-actions {
            float: left;
        }
    }
}

// todo

.todo-list {
    li {
        .custom-control-label {
            font-weight: $font-weight-medium;
        }
    }
}

// Knob chart
.knob-chart {
    > div {
        display: inherit !important;
    }

    input {
        outline: none !important;
    }
}

[data-plugin="knob"] {
    font-family: $font-family-base !important;
}

// Highcharts
.highcharts-popup {
    max-height: 100% !important;
    // overflow-y: scroll !important;
    overflow: hidden;
    width: 100% !important;
    min-width: 300px !important;
    max-width: 650px !important;
    position: absolute !important;
    left: 0px !important;
    top: 0% !important;
    height: 100% !important;
}

.highcharts-popup button {
    float: left !important;
    text-transform: uppercase !important;
    font-size: 1.3em !important;
    padding: 8px 12px !important;
    position: absolute !important;
    width: 25% !important;
    top: 0px !important;
}

.highcharts-bindings-wrapper ul.highcharts-indicator-list, .highcharts-indicator-list {
    height: calc(100% - 40px) !important
}

.highcharts-indicator-list li {
    height: auto !important;
}

.highcharts-popup-lhs-col {
    width: 50% !important;
    border: none !important;
}

.highcharts-popup-rhs-col {
    width: 50% !important;
    border: none !important;
}

.highcharts-input-group {
    display: none !important;
}

@media (min-width: 1199.99px) {
    .highcharts-popup {
        position: fixed !important;
        height: calc(100vh - 70px);
        overflow: hidden !important;
        left: 240px !important;
        min-width: calc(100% - 240px) !important;
    }

    .highcharts-bindings-wrapper ul.highcharts-indicator-list, .highcharts-indicator-list {
        height: calc(100vh - 110px) !important;
    }
}


@media (min-width: 991px) and (max-width: 1199.98px) {
    .highcharts-popup {
        position: fixed !important;
        height: calc(100vh - 70px);
        overflow: hidden !important;
        left: 70px !important;
        min-width: calc(100% - 70px) !important;
    }

    .highcharts-bindings-wrapper ul.highcharts-indicator-list, .highcharts-indicator-list {
        height: calc(100vh - 110px) !important;
    }
}


@include media-breakpoint-down(md) {
    .highcharts-popup {
        position: fixed !important;
        height: calc(100vh - 70px);
        overflow: hidden !important;
        left: 0px !important;
        min-width: 100% !important;
    }

    .highcharts-bindings-wrapper ul.highcharts-indicator-list, .highcharts-indicator-list {
        height: calc(100vh - 110px) !important;
    }
}


.animate {
    -webkit-animation-name: spinner; /* Chrome, Safari, Opera */
    -webkit-animation-duration: 1s; /* Chrome, Safari, Opera */
    -webkit-animation-name: spinner;
    -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes spinner {
    0% {
    }

    50% {
    }

    100% {
    }
}

/* Standard syntax */
@keyframes spinner {
    0% {
        background-color: transparent;
    }

    25% {
        background-color: $gray-300;
    }

    50% {
        background-color: $gray-400;
    }

    75% {
        background-color: $gray-300;
    }

    100% {
        color: transparent;
    }
}

.fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 2s;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.slide {
    position: absolute;
    left: -100px;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
    animation-delay: 0.5s;
}

@-webkit-keyframes slide {
    100% {
        left: 0;
        position: inherit;
    }
}

@keyframes slide {
    100% {
        left: 0;
        position: inherit;
    }
}


// BS override

.fixed-top {
    box-shadow: 1px 1px 1px rgb(50 58 70 / 10%) !important;
    background: $bg-leftbar !important;
}

.fixed-bottom {
    bottom: 96px !important;
    left: auto;
    right: 22px !important;
    z-index: 1001!important;
}

.fixed-second-bottom {
    bottom: 96px !important;
    left: auto;
    right: 188px !important;
    position: fixed;
    z-index: 1000!important;
}

.frame-app-container {
    background: transparent;
    overflow: hidden!important;
    overflow-x: hidden!important;
    overflow-y: hidden!important;
    height: calc(100vh - 75px);
    width: 100%;
    position: fixed !important;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

@media (min-width: 991px) {
    .frame-app-container {
        height: 100vh !important;
    }

    .video-container {
        height: 100vh !important;
    }

    .fixed-bottom {
        bottom: 32px !important;
        left: auto;
        right: 32px !important;
    }

    .fixed-second-bottom {
        bottom: 32px !important;
        left: auto;
        right: 198px !important;
        position: fixed;
    }

}

//bg glyph

#wrap {
    position: relative;
}

.inner {
    position: relative;
    z-index: 2;
}

#wrap .glyphicon {
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    position: absolute;
    font-size: 180px;
    margin-top: 10%;
    opacity: .06;
}
