//
// topbar.scss
//
// Disable the blue webkit search cancel icon
#top-search::-webkit-search-cancel-button {
    position: relative;
    right: -20px;
}

.mobile-menu-span {
    display: block;
    font-size: 0.8em;
    font-family: $font-family-monospace;
    text-align: center;
}

.navbar-custom {
    background-color: $bg-topbar-light;
    box-shadow: none;
    padding: 0 10px 0 0;
    position: fixed;
    left: $leftbar-width;
    right: 0;
    top: 0;
    height: $topbar-height;
    transition: all .1s ease-out;
    z-index: 1001;

    .logo-box {
        display: none;
    }

    .topnav-menu {
        > li {
            float: left;
        }

        .nav-link {
            padding: 0 15px;
            color: $gray-700;
            min-width: 32px;
            display: block;
            line-height: $topbar-height;
            text-align: center;
            max-height: $topbar-height;
        }
    }

    .dropdown.show {
        .nav-link {
            background-color: $gray-100;
        }
    }

    .container-fluid {
        padding: 0;
    }
    /* Search */
    .search-bar {
        width: 60%;
    }

    .app-search {
        height: $topbar-height;
        display: table;
        width: 100%;

        .app-search-box {
            display: table-cell;
            vertical-align: middle;
            position: relative;

            input::placeholder {
                font-size: $h4-font-size;
                font-family: $font-family-secondary;
                font-weight: 800;
                text-transform: uppercase;
                color: $gray-600;
            }
        }

        .form-control {
            border: none;
            height: 70px;
            padding-left: 20px;
            padding-right: 0;
            color: $body-color;
            background-color: $bg-topbar-light;
            box-shadow: none;
            border-radius: 0;
            font-size: $h4-font-size;
            font-family: $font-family-secondary;
            font-weight: 800;
            text-transform: uppercase;
        }

        .input-group-append {
            margin-left: 0;
            z-index: 4;
        }

        .btn {
            background-color: $bg-topbar-light;
            border-color: transparent;
            color: $gray-700;
            border-radius: 0;
            box-shadow: none !important;
            font-size: $h4-font-size;
            font-family: $font-family-secondary;
            font-weight: 800;
        }
    }

    .button-menu-mobile {
        border: none;
        // color: $gray-700;
        // display: inline-block;
        height: inherit; // $topbar-height
        line-height: inherit; // $topbar-height;
        // width: 60px;
        //  background-color: transparent;
        // font-size: 24px;
        cursor: pointer;
    }

    .button-menu-mobile.disable-btn {
        display: none;
    }
}


/* Two Column Menu */

body[data-sidebar-size=condensed] .logo span.logo-lg {
    display: none;
}

/* Notification */
.noti-scroll {
    max-height: 230px;
}

.notification-list {
    margin-left: 0;

    .noti-title {
        background-color: transparent;
        padding: 15px 20px;
    }

    .noti-icon-badge {
        display: inline-block;
        position: absolute;
        top: 16px;
        right: 10px;
    }

    .notify-item {
        padding: 12px 20px;

        .notify-icon {
            float: left;
            height: 36px;
            width: 36px;
            font-size: 18px;
            line-height: 38px;
            text-align: center;
            margin-right: 10px;
            border-radius: 50%;
            color: $white;
        }

        .badge {
            margin-top: 5px;
        }

        .notify-details {
            margin-bottom: 5px;
            overflow: hidden;
            margin-left: 45px;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: $gray-800;

            b {
                font-weight: 500;
            }

            small {
                display: block;
            }

            span {
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 13px;
            }
        }

        .user-msg {
            margin-left: 45px;
            white-space: normal;
            line-height: 16px;
        }
    }

    .profile-dropdown {
        .notify-item {
            padding: 7px 20px;
        }
    }
}

.noti-icon {
    font-size: 1.3em;
    vertical-align: middle;
    display: block;
    line-height: 0.90em;
    text-align: center !important;
    // font-size: 21px;
    // vertical-align: middle;
}

.profile-dropdown {
    min-width: 170px;

    i {
        vertical-align: middle;
        margin-right: 5px;
        font-size: 16px;
    }
}

.nav-user {
    padding: 0 12px !important;

    img {
        height: 32px;
        width: 32px;
    }
}

// Full Screen
.fullscreen-enable {
    [data-toggle="fullscreen"] {
        .fe-maximize::before {
            content: "\e88d";
        }
    }
}

@include media-breakpoint-down(md) {
    .logo-box {
        width: $leftbar-width-condensed !important;
        padding-right: 0 !important;

        .logo-lg {
            display: none !important;
        }

        .logo-sm {
            display: block !important;
        }
    }

    .navbar-custom {
        left: 0 !important;
    }
}

@media (max-width: 600px) {
    .navbar-custom {
        .dropdown {
            position: static;

            .dropdown-menu {
                left: -50px !important;
                right: 10px !important;
                min-width: 20rem;
            }
        }
    }
}

// Enlarge menu
body[data-sidebar-size="condensed"] {
    .navbar-custom {
        left: $leftbar-width-condensed;
    }
}


// Dark Topbar

body[data-topbar-color="dark"] {
    .navbar-custom {
        background-color: $bg-leftbar !important;
        box-shadow: 0 -1px 1px rgba(50,58,70,.1);
        // background: radial-gradient(circle,#fff 0,#f8fffe 100%);
    }
}


// Light Topbar

body[data-topbar-color="light"] {
    .navbar-custom {
        background-color: $body-bg !important;
        box-shadow: 0 1px 1px rgba(50,58,70,.1);

        .topnav-menu {
            .nav-link {
                color: $gray-700;
            }
        }

        .dropdown.show {
            .nav-link {
                background-color: rgba($dark, 0.03);
            }
        }

        .button-menu-mobile {
            color: $dark;
        }
        /* Search */
        .app-search {
            input::placeholder {
                color: $gray-700 !important;
                font-size: $h4-font-size;
                font-family: $font-family-secondary;
                font-weight: 800;
            }

            .form-control {
                color: $body-color;
                background-color: $bg-topbar-light;
                border-color: $bg-topbar-light;
            }

            .btn {
                background-color: $bg-topbar-light;
                color: $gray-400;
            }
        }
    }

    .logo-dark {
        display: block;
    }

    .logo-light {
        display: none;
    }
}

body[data-sidebar-color="dark"] {
    .navbar-custom {
        box-shadow: 0 -1px 0px $gray-200;
    }
}

body[data-sidebar-size=condensed][data-layout-mode="two-column"] {
    .logo span.logo-lg {
        display: block;
    }
}

body[data-topbar-color="light"][data-sidebar-color="light"] {
    .logo-box {
        background-color: $white;
    }
}

@include media-breakpoint-down(lg) {
    body[data-layout-mode="horizontal"],
    body[data-layout-mode="detached"] {
        .navbar-custom {
            padding: 0 !important;
        }
    }
}

@include media-breakpoint-down(md) {
    body[data-layout-mode="horizontal"] {

        .navbar-custom {
            padding: 0 calc(#{$grid-gutter-width/ 2}) !important;
        }
        // Navbar Button - Horizontal
        .navbar-toggle {
            border: 0;
            position: relative;
            padding: 0;
            margin: 0;
            cursor: pointer;

            .lines {
                width: 25px;
                display: block;
                position: relative;
                height: 16px;
                transition: all .5s ease;
                margin-top: calc(#{$topbar-height - 16px} / 2);
            }

            span {
                height: 2px;
                width: 100%;
                background-color: $white;
                display: block;
                margin-bottom: 5px;
                transition: transform .5s ease;

                &:first-child {
                    width: 75%;
                }

                &:last-child {
                    width: 50%;
                }
            }

            &.open {
                span {
                    position: absolute;

                    &:first-child {
                        top: 7px;
                        transform: rotate(45deg);
                        width: 100%;
                    }

                    &:nth-child(2) {
                        visibility: hidden;
                    }

                    &:last-child {
                        width: 100%;
                        top: 7px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        .logo-box {
            width: 45px !important;
        }
    }

    body[data-topbar-color="light"] {
        .navbar-toggle {

            span {
                background-color: $gray-700;
            }
        }
    }
}


body[data-layout-mode="horizontal"] {

    &[data-layout-width="boxed"] {
        .navbar-custom {
            max-width: $boxed-layout-width !important;
        }
    }

    .button-menu-mobile {
        display: none;
    }

    .logo-box {
        width: auto;
        padding-right: 50px;
        float: left;
        position: relative;
        background-color: transparent;
    }

    @media (min-width: 992px) and (max-width: 1366px) {
        .logo-box {
            padding-left: 0px;
        }
    }
}

@media (max-width: 360px) {
    .navbar-custom {
        .topnav-menu {
            .nav-link {
                padding: 0 12px;
            }
        }

        .button-menu-mobile {
            width: 45px;
        }
    }
}

//
// Detached
//

body[data-layout-mode="detached"] {
    .logo-box {
        float: left;
        position: relative;
    }

    .navbar-custom {
        left: 0;
        right: 0;

        .logo-box {
            display: block;
        }
    }
}

.button-new {
    padding: 0 20px;
    line-height: 36px;
    margin: 16px 0;
}


// Show small logo when in mobile and increase padding for footer to move up


@media (max-width: 991.98px) {
    .navbar-custom .logo-box {
        position: inherit;
        display: block;
        max-height: 70px;
        line-height: 70px;
        padding: 2px 0px;
    }


    .search-bar {
        width: 70% !important;
        margin-left: 50px;
    }

    .navbar-custom {
        bottom: 0;
        top: auto;
    }

    .mobile-space {
        margin-right: 0px;
    }


    .dropdown-menu {
        position: absolute;
    }


    .navbar-custom .button-menu-mobile {
        bottom: 0;
        // position: absolute;
    }

    .logo span.logo-sm {
        font-weight: 800;
        font-size: 26px;
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
    }
}

@include media-breakpoint-down(md) {
    .navbar-custom {
        height: 70px;
    }

    .navbar-custom .logo-box {
        max-height: 70px;
        line-height: 70px;
    }

    .navbar-custom .app-search .form-control {
        height: 70px;
    }

    .navbar-custom .app-search {
        height: 70px;
    }

    .navbar-custom .button-menu-mobile {
        // height: 70px;
        // line-height: 70px;
    }
}
