//
// preloader.scss
//

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // bottom: 0;
    background-color: $auth-bg;
    z-index: 9999;
    height: 6px;
}


#status {
    width: 100%;
    height: 4px;
    overflow: hidden;
    background-color: $info;
    margin: auto;
}

.spinner {
    background-color: $auth-bg;
    margin-top: 0;
    margin-left: -80px;
    animation-name: bounce_loadingProgressG;
    -o-animation-name: bounce_loadingProgressG;
    -ms-animation-name: bounce_loadingProgressG;
    -webkit-animation-name: bounce_loadingProgressG;
    -moz-animation-name: bounce_loadingProgressG;
    animation-duration: 0.7s;
    -o-animation-duration: 0.7s;
    -ms-animation-duration: 0.7s;
    -webkit-animation-duration: 0.7s;
    -moz-animation-duration: 0.7s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -o-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -moz-animation-timing-function: linear;
    width: 80px;
    height: 4px;
}



@keyframes bounce_loadingProgressG {
    0% {
        margin-left: -100%;
    }

    100% {
        margin-left: 100%;
    }
}

@-o-keyframes bounce_loadingProgressG {
    0% {
        margin-left: -100%;
    }

    100% {
        margin-left: 100%;
    }
}

@-ms-keyframes bounce_loadingProgressG {
    0% {
        margin-left: -100%;
    }

    100% {
        margin-left: 100%;
    }
}

@-webkit-keyframes bounce_loadingProgressG {
    0% {
        margin-left: -100%;
    }

    100% {
        margin-left: 10%;
    }
}

@-moz-keyframes bounce_loadingProgressG {
    0% {
        margin-left: -100%;
    }

    100% {
        margin-left: 100%;
    }
}

