// 
// page-title.scss
// 0 1px 1px rgba(50,58,70,.1)

.page-title-box {
    background-color: none;
    align-items: center;
    justify-content: space-between;
    display: none;
    margin: 0 -27px 30px;
    padding: 15px 27px;
    box-shadow: $components-shadow;

    .page-title {
        font-size: 1.1rem;
        margin: 0;
        color: $gray-900;
    }

    .breadcrumb {
        padding: 0;
    }
}

.page-title-box-alt { 
    background-color: transparent;
    padding: 24px 27px;
    box-shadow: none;
    margin-bottom: 0;
}

@media (max-width: 639px) {
    .page-title-box {
        display: none;

        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        .page-title-right {
            display: block;
            padding-top: 5px;
        }
    }
}