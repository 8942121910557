//
// general.scss
//

html {
    position: relative;
    min-height: 100%;
}

body {
    overflow-x: hidden;
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
}

body.brand {
    background-image: url(../images/obermind_big_dark_bg.png);
    background-size: auto;
    background-position: left top;
    background-repeat: no-repeat;
}

.img-wrap {
    background-size: cover;
    height: 667px;
    position: absolute;
    right: 0px;
}

.p-hero {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}


@include media-breakpoint-down(md) {
    .img-wrap {
        background-size: cover;
        height: 100vw;
        position: relative;
        right: 0px;
        min-height: 100vw!important;
    }

    .p-hero {
        padding-top: 80px !important;
        padding-bottom: 0px !important;
    }
}

@include media-breakpoint-down(md) {
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
        padding-right: 0px;
        padding-left: 0px;
    }
}

// mobile spacing overrides

@include media-breakpoint-down(md) {
    .list-group {
        margin-right: -1rem;
        margin-left: -1rem;
    }

    .card-mobile-form {
        margin-right: -2rem;
        margin-left: -2rem;
    }

    .list-group-horizontal {
        flex-direction: column;
    }
}
