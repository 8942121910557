//
// swiper.scss
//

.swiper {
    width: 100%;
    height: 100%;
    overflow: visible !important;
}

.swiper-slide {
    height: 150px !important;
}


@include media-breakpoint-down(xs) {
    .swiper-slide {
        height: 150px !important;
    }
}


@include media-breakpoint-down(md) {
    .swiper-slide-next {
        opacity: 0.5;
    }

    .swiper-slide-prev {
        opacity: 0.5;
    }
}

@include media-breakpoint-up(md) {
    .swiper-slide-next + .swiper-slide {
        opacity: 0.5;
    }

    .swiper-slide-prev {
        opacity: 0.5;
    }

    .swiper-slide {
        height: 183px !important;
    }
}

@include media-breakpoint-up(lg) {
    .swiper-slide-next + .swiper-slide + .swiper-slide {
        opacity: 0.5;
    }

    .swiper-slide {
        height: 183px !important;
    }
}