.popup-container {
  position: fixed;
  transition: all 0.5s ease;
  z-index: 1002;
  width: 100%;
  height: 100vh;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}

.popup-wills {
  width: 550px;
  height: auto;
  border-radius: 0.75rem;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 0 45px 0 rgba(0,0,0,.12);
  border: 1px solid #ebebeb;
  margin-top: 100px;
}

.flex-btn-popup {
  display: block;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}

.btn-close-popup-will {
  margin-left: 0px;
  margin-top: 10px !important;
  padding: 10px 16px;
  border-radius: 8px
}

.onClose-icon {
  font-size: 30px;
  cursor: pointer;
}

.text-popup {
  margin: 8px 0;
  font-weight: normal;
  letter-spacing: 1px;
  font-size: 14px;
}

@media (max-width: 650px) {
  .popup-wills {
    width: 100%;
    padding: 15px;
    overflow-y: scroll;
  }

  .flex-btn-popup {
    flex-direction: column;
    width: 100%;
  }


  .flex-btn-popup a {
    width: 100%;
  }

  .flex-btn-popup div {
    margin: 0 0 0 auto;
  }

  .btn-close-popup-will {
    width: 100%;
    margin-left: 0;
    margin-top: 8px !important;
    padding: 8px 16px;
  }

  .text-popup {
    font-size: 12px;
  }
}