// 
// stepperWills.scss
//

.stepper-icon-width-8px { 
    width: 8px !important; 
}

.stepper-icon-height-8px { 
    height: 8px !important; 
}

.stepper-icon-active { 
    color: #3164FF
}