//
// toastr.scss
//

.jq-toast-single {
    padding: 15px 5px 15px 24px !important;
    font-family: $font-family-monospace !important;
    background-color: $gray-200 !important;
    opacity: 0.9;
    font-size: 13px;
    line-height: 22px;
    color: $white !important;
    width: 100% !important;
    margin: 0px !important;
    border-radius: 0px;
    background-color: $black !important;

    h2 {
        font-family: $font-family-monospace !important;
    }

    a {
        font-size: $font-family-monospace !important;

        &:hover {
            color: $primary !important;
        }
    }
}

.jq-has-icon {
    padding: 10px 10px 10px 50px;
    background-repeat: no-repeat;
    background-position: 4px !important;
    background-size: 16px;
}

.close-jq-toast-single {
    position: absolute;
    top: -12px !important;
    left: -12px;
    font-size: 20px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    background-color: $gray-300 !important;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    color: $primary !important;
}

.jq-toast-loader {
    height: 3px;
    top: 0;
    border-radius: 0;
}

@each $color,$value in $theme-colors {
    .jq-icon-#{$color} {
        background-color: #{$value} !important;
        color: $primary !important;
        border-color: #{$value};
    }
}

// For error
.jq-icon-error {
    background-color: $danger;
    color: $primary !important;
    border-color: $danger;
}

@include media-breakpoint-down(md) {
    .jq-toast-wrap.bottom-left {
        bottom: 70px !important;
        left: 0px !important;
        width: 100% !important;
    }
}

.jq-toast-single {
    margin-bottom: 3px!important;
}
